/* 
  Note: !! The same file is used at docs and marketing apps, replicate any changes
  there too.
*/
/*
  Name:       material
  Author:     Mattia Astorino (http://github.com/equinusocio)
  Website:    https://material-theme.site/
*/

.CodeMirror-vscrollbar {
  background-color: #151515;
}

.cm-s-material-darker.CodeMirror {
  background-color: #151515;
  color: #daecec;
  font-size: 15px;
  font-family: 'Fira Mono', 'Courier New', Courier, monospace;
  font-weight: normal;
  height: 100%;
  width: 100%;
  position: absolute;
  line-height: 1.5;
}

.cm-s-material-darker .CodeMirror-gutters {
  background: #212121;
  color: #6c6969;
  border: none;
}

.cm-s-material-darker .CodeMirror-guttermarker,
.cm-s-material-darker .CodeMirror-guttermarker-subtle,
.cm-s-material-darker .CodeMirror-linenumber {
  color: #6c6969;
}

.cm-s-material-darker .CodeMirror-cursor {
  border-left: 1px solid #ffffff;
}

.cm-s-material-darker div.CodeMirror-selected {
  background: rgba(97, 97, 97, 0.2);
}

.cm-s-material-darker.CodeMirror-focused div.CodeMirror-selected {
  background: rgba(97, 97, 97, 0.2);
}

.cm-s-material-darker .CodeMirror-line::selection,
.cm-s-material-darker .CodeMirror-line > span::selection,
.cm-s-material-darker .CodeMirror-line > span > span::selection {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material-darker .CodeMirror-line::-moz-selection,
.cm-s-material-darker .CodeMirror-line > span::-moz-selection,
.cm-s-material-darker .CodeMirror-line > span > span::-moz-selection {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material-darker .CodeMirror-activeline-background {
  background: #424242;
}

.cm-s-material-darker .CodeMirror-activeline-gutter > .CodeMirror-linenumber {
  color: rgba(255, 255, 255, 0.87);
}

.cm-s-material-darker .cm-keyword {
  color: #c792ea;
}

.cm-s-material-darker .cm-operator {
  color: #89ddff;
}

.cm-s-material-darker .cm-variable-2 {
  color: #f07178;
}

.cm-s-material-darker .cm-variable-3,
.cm-s-material-darker .cm-type {
  color: #f07178;
}

.cm-s-material-darker .cm-builtin {
  color: #ffcb6b;
}

.cm-s-material-darker .cm-atom {
  color: #f78c6c;
}

.cm-s-material-darker .cm-number {
  color: #ff5370;
}

.cm-s-material-darker .cm-def {
  color: #86a8f0;
}

.cm-s-material-darker .cm-string {
  color: #85cf84;
}

.cm-s-material-darker .cm-string-2 {
  color: #f07178;
}

.cm-s-material-darker .cm-comment {
  color: #7d7878;
  font-style: italic;
}

.cm-s-material-darker .cm-variable {
  color: #daecec;
}

.cm-s-material-darker .cm-tag {
  color: #ff5370;
}

.cm-s-material-darker .cm-meta {
  color: #ffcb6b;
}

.cm-s-material-darker .cm-attribute {
  color: #c792ea;
}

.cm-s-material-darker .cm-property {
  color: #c792ea;
}

.cm-s-material-darker .cm-qualifier {
  color: #decb6b;
}

.cm-s-material-darker .cm-error {
  color: rgba(255, 255, 255, 1);
  background-color: #ff5370;
}

.cm-s-material-darker .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}

/* when matches are highlighted, match color should become black for contrast */
.cm-atom.cm-matchhighlight,
.cm-attribute.cm-matchhighlight,
.cm-builtin.cm-matchhighlight,
.cm-comment.cm-matchhighlight,
.cm-def.cm-matchhighlight,
.cm-keyword.cm-matchhighlight,
.cm-meta.cm-matchhighlight,
.cm-number.cm-matchhighlight,
.cm-operator.cm-matchhighlight,
.cm-property.cm-matchhighlight,
.cm-qualifier.cm-matchhighlight,
.cm-string.cm-matchhighlight,
.cm-string-2.cm-matchhighlight,
.cm-tag.cm-matchhighlight,
.cm-type.cm-matchhighlight,
.cm-variable.cm-matchhighlight,
.cm-variable-2.cm-matchhighlight,
.cm-variable-3.cm-matchhighlight {
  color: #000000;
}
